@media  screen and (max-width:480px) {
    .about {
        padding-bottom: 0;
    }

    .shef {
        background-position: bottom;
        &::after {
          content: "";
          display: block;
          padding-top: 50%;
        }
    }

    .menu {
        &__right {
            & > h2 {
                font-size: 90px;
            }
        }

        &__left {
            position: relative;
            z-index: 1;
        }

        &__des {
            &-right {
                width: 460px;
            }
        }
    }
    .dinner {
        &__right {
            margin-left: 15%;
            margin-bottom: 40px;

            & > h2 {
                font-size: 90px;
                
            }
        }

        &__des {
            &-right {
                width: 460px;
            }
        }

        &__ttl {
            &-sp {
                width: 38%;
            }
        }
    }
    iframe {
        height: 200px;
      }
}
