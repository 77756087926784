@media screen and (max-width:780px) {
    video {
        height: 400px;
    }
    .video {
        &-container {
            height: 400px;
        }
    }
    
    .farm {
        background-position:bottom;

        &__inner {
            padding: 0 0 60px 0;
        }
    }

    .menu {
        &__right {
            & > h2 {
                font-size: 110px;
            }
        }

        &__left {
            position: relative;
            z-index: 1;
        }

    }
    .dinner {
        &__right {
            & > h2 {
                font-size: 110px;
            }
        }
       
    }
    .shop {
        background-position: bottom;
        &__inner {
            margin: 0 auto 100px;
        }
    }
}
