.site__title > a > img {
  position: relative;
  z-index: 1000;
}
.main {
  &__logo {
    position: fixed;
    top: 20px;
    left: 60px;
    z-index: 100;
  }
}
/*
ハンバーガーメニュー
解説は、「中級編：ストアサイト（インテリア）」のCSS参照
*/
#navi {
  display: block;
  position: fixed;
  left: -300px;
  width: 30%;
  height: 100%;
  color: #fff;
  padding: 60px 25px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 58%, rgba(0, 0, 0, 0.8) 62%, rgba(0, 0, 0, 0.2) 85%, rgba(0, 0, 0, 0) 100%);
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s;
  z-index: 20;
  opacity: 0;
}

.open #navi {
  left: 0;
  opacity: 1;
}
#navi ul {
  margin: 0;
  padding: 0;
}
#navi ul.nav-menu {
  margin-bottom: 60px;
  margin-top: 30%;
}
#navi ul.nav-menu li {
    list-style: none;
  position: relative;
  margin-left: 100px;
  padding: 20px 0;
}
#navi ul.nav-menu li > a {
    position: relative;
    font-size: 14px;
    color: #fff;
    font-family: 'Hina Mincho', serif;
}

#navi ul.nav-menu li > a:hover {
    text-decoration: none;
    opacity: 0.7;
}
#navi ul.nav-menu li > a::before {
    content: '';
    position: absolute;
    top: 12px;
    left: -17px;
    width: 10px;
    height: 1px;
    background-color: #fff;
}

#navi ul.nav-sns li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: 90px;
}
#navi ul.nav-sns li > a {
    color: #fff;
}
#navi ul.nav-sns li > a:hover {
    text-decoration: none;
    opacity: 0.7;
}
#navi ul.nav-sns li > a > img {
    position: relative;
    bottom: 3px;
    right: 10px;
}

.toggle_btn {
  display: block;
  position: fixed;
  top: 120px;
  left: 60px;
  width: 50px;
  height: 50px;
  transition: all 0.5s;
  cursor: pointer;
  z-index: 20;
  border: 1px solid #fff;
  border-radius: 50%;
}
.toggle_btn span {
  display: block;
  position: absolute;
  left: 11px;
  width: 25px;
  height: 1px;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.5s;
}
.toggle_btn span:nth-child(1) {
  top: 12px;
}
.toggle_btn span:nth-child(2) {
  top: 24px;
}
.toggle_btn span:nth-child(3) {
  bottom: 12px;
  width: 13px;
}
.open .toggle_btn span {
  background-color: #fff;
  width: 25px;
}
.open .toggle_btn span:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(-315deg);
  transform: translateY(10px) rotate(-315deg);
  top: 14px;
}
.open .toggle_btn span:nth-child(2) {
  opacity: 0;
}
.open .toggle_btn span:nth-child(3) {
  -webkit-transform: translateY(-10px) rotate(315deg);
  transform: translateY(-10px) rotate(315deg);
  width: 25px;
}
#mask {
  display: none;
  transition: all 0.5s;
}
.open #mask {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 0.8;
  z-index: 10;
  cursor: pointer;
}

