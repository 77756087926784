@media  screen and (max-width:1280px) {
    
    .about {
        background-position: 300px -200px;
    }
    .shop {
        background-position: -240px 0;
    }
}
@media screen and (max-width:1100px) {
    .farm__blk {
        width: 54%;
    }
}

@media  screen and (max-width:1700px) {
        
    .farm {
        background-position: 200px 0;
        
    }
    #navi ul.nav-menu {
        margin-top: 130px;
        margin-bottom:30px;
    }
    #navi ul.nav-menu li {
        padding: 15px 0;
    }
}
