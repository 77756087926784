html {
  scroll-behavior: smooth;
}
body {
  font-family: "Shippori Mincho", serif;
  letter-spacing: 0.05em;
}
img {
  max-width: 100%;
  vertical-align: bottom;
}

.fo-ttl {
  font-family: "Hina Mincho", serif;
}

.mr-lr {
  margin-right: 100px;
}
.content-width {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px;
}

#global-container {
  overflow: hidden;
}

.mobile-menu {
  &__btn {
    display: none;
  }
}

.header {
  display: none;
}

.scroll {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  & > p {
    font-size: 12px;
    color: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 60px;
      left: -30%;
      width: 60px;
      height: 1px;
      background-color: #fff;
      transform: rotate(90deg);
      /*線の動き1.4秒かけて動く。永遠にループ*/
      animation: pathmove 1.4s ease-in-out infinite;
    }
  }
}

/*高さ・位置・透過が変化して線が上から下に動く*/
@keyframes pathmove {
  0% {
    height: 0;
    top: 55px;
    opacity: 0;
  }
  30% {
    height: 1px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 80px;
    opacity: 0;
  }
}

.video-container {
  height: 100vh;
  // overflow: hidden;
  position: relative;
}

video {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

// 背景色
#news,
#about,
#shef,
#shop {
  background-color: #000;
}

.ttl {
  width: 90px;
  @extend .fo-ttl;

  &__bar {
    text-align: center;
  }

  &__blk {
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 10px;
  }

  &__ja {
    writing-mode: vertical-lr;
    color: #fff;
    font-size: 30px;
    letter-spacing: 0.05em;
  }
  &__en {
    writing-mode: vertical-lr;
    color: #664806;
    font-size: 20px;
    letter-spacing: 0.35em;
  }
  &__sp {
    display: none;
  }
}

// ニュース
.news {
  padding: 190px 0;
  &__inner {
    @extend .content-width;
  }

  &__blk {
    display: flex;
    justify-content: space-evenly;
  }

  &__ul {
    padding: 0;
    margin: 0;
  }

  &__li {
    list-style: none;
    color: #fff;
    margin-bottom: 35px;
    font-size: 13px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;

    & > a {
      color: #fff;
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  &__more {
    text-align: right;
    width: 90%;

    & > a {
      color: #fff;
      position: relative;
      &:hover {
        color: #fff;
        text-decoration: none;
      }

      &::after {
        content: "";
        display: block;
        background-image: url(../images/news_yajirushi.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 90px;
      }
    }
  }
}

// 旬輝のこと
.about {
  background-image: url(../images/about_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 600px -200px;
  padding-bottom: 200px;

  &__inner {
    width: 60%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 100px 0;
  }

  &__blk {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 5%;
  }

  &__ttl {
    &-sp {
      display: none;
    }
  }

  &__txt {
    height: 370px;
    & > p {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0;
    }
  }
}

// シェフのこと
.shef {
  background-image: url(../images/chef_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -360px 0px;

  &::after {
    content: "";
    display: block;
    padding-top: 10%;
  }

  &__inner {
    width: 70%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
  }

  &__ttl {
    &-sp {
      display: none;
      margin-top: 70px;
    }
  }

  &__blk {
    width: 60%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 300px;
    position: relative;
    bottom: 100px;
  }

  &__txt {
    height: 370px;
    & > p {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0;

      & > span {
        display: block;
        text-align: end;

        & .br {
          display: none;
        }
      }
    }
  }
}

// 画像スライド
.slick-slide {
  & > img {
    width: 100%;
    height: 100%;
  }
}

// 生産者のこと
#farm {
  background-image: url(../images/farm_bg_img.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.farm {
  background-image: url(../images/farm_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 500px -100px;
  padding-bottom: 200px;

  &__inner {
    width: 70%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 100px 0;
  }

  &__blk {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 110px;
  }

  &__ttl {
    &-sp {
      display: none;
    }
  }

  &__txt {
    height: 370px;
    display: flex;
    flex-direction: row-reverse;

    & > p:nth-child(1) {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0;
    }
    & > p:nth-child(2) {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0;
      text-align: end;
    }
  }
}

//メニュー全体
.menu-price {
  font-size: 18px !important;
  text-align: left;
}
.menu-ttl {
  &__wrap {
    text-align: center;
    @extend .fo-ttl;
  }
  &__ja {
    writing-mode: vertical-lr;
    color: #fff;
    font-size: 30px;
    position: relative;
    right: 2px;
    top: 6px;
    letter-spacing: 0.05em;
  }
  &__en {
    writing-mode: vertical-lr;
    color: #664806;
    font-size: 20px;
    position: relative;
    right: 3px;
    bottom: 45px;
    letter-spacing: 0.35em;
  }
  &__sp {
    display: none;
  }
}
.menu {
  background-image: url(../images/menu_bg_wrap.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: -700px;

  &__ttl {
    text-align: center;

    &-sp {
      display: none;
    }
  }

  &__flex {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__left {
    &-ttl {
      &__ja {
        font-size: 26px;
        color: #664806;
        margin-top: 10px;
        display: block;
      }
    }
    & > p {
      margin-bottom: 0;
      margin-top: 15px;
      font-size: 14px;
    }
  }

  &__right {
    & > h2 {
      font-size: 190px;
      color: #fff;
      margin-bottom: 0;
    }
  }

  &__des {
    &-flex {
      width: 80%;
      max-width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      bottom: 80px;
      position: relative;
      z-index: 1;
    }
    &-left {
      writing-mode: vertical-rl;
      height: 370px;
      text-align: left;
      line-height: 2;

      & > p {
        margin: 0;
        font-size: 14px;
      }
    }
    &-right {
      width: 70%;
    }
  }

  &__img {
    &-inner {
      width: 90%;
      max-width: 1200px;
      margin: 0 auto -300px;
    }
    &-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: -600px;
    }

    &-left {
      position: relative;
      left: 30px;
    }

    &-right {
      margin-top: 800px;
      position: relative;
      right: 30px;
      z-index: 1;
    }
  }
}

// ランチメニュー背景
.lunch {
  &__menu {
    &-bg {
      background-image: url(../images/lunch_bg_img.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      height: 1081px;
      padding: 100px 0 0 0;
    }
  }
}

// ディナーメニュー背景
.dinner {
  &__menu {
    &-bg {
      background-image: url(../images/lunch_bg_img02.png);
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      padding-top: 500px;
    }
  }
}

.dinner {
  background-image: url(../images/menu_bg_wrap.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &__ttl {
    text-align: center;

    &-sp {
      display: none;
    }
  }

  &__flex {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    text-align: right;

    & > p {
      margin-bottom: 0;
      margin-top: 15px;
      font-size: 14px;
    }
  }

  &__right {
    & > h2 {
      font-size: 190px;
      color: #fff;
      margin-bottom: 0;
    }
  }

  &__des {
    &-flex {
      width: 80%;
      max-width: 1100px;
      margin: 0 auto -50px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: center;
      position: relative;
      bottom: 80px;
      position: relative;
      z-index: 1;
    }
    &-left {
      writing-mode: vertical-rl;
      height: 370px;
      text-align: left;
      line-height: 2;

      & > p {
        margin: 0;
        font-size: 14px;
      }
    }
    &-right {
      width: 70%;
    }
  }

  &__img {
    &-inner {
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
    }
    &-flex {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-top: -600px;
    }

    &-left {
      position: relative;
      right: 30px;
    }

    &-right {
      margin-top: 800px;
      position: relative;
      z-index: 1;
      left: 30px;
    }

    // 最下部背景
    &-bgbottom {
      background-image: url(../images/dinner_bg_img02.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      bottom: 600px;

      &::after {
        content: "";
        display: block;
        padding-top: 70%;
      }
    }
  }
}

// 店舗のこと
.shop {
  background-image: url(../images/shop_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 200px 0;
  padding-bottom: 300px;

  &__inner {
    width: 70%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  &__blk {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 300px;
  }

  &__ttl {
    &-sp {
      display: none;
    }
  }

  &__txt {
    & > p:nth-child(1) {
      color: #fff;
      line-height: 2;
      font-size: 14px;
      margin: 0;
      border-bottom: 1px solid #fff;
      padding-bottom: 30px;
    }
    & > p:nth-child(2) {
      color: #fff;
      line-height: 2;
      font-size: 13px;
      margin: 0;
      padding-top: 30px;
    }
  }

  &__ttl {
    margin-right: 100px;
  }
  &__span {
    font-size: 18px;
  }
}

//予約
.reserve {
  background-color: #000;
  text-align: center;
  padding: 100px 0;
}

//お問い合わせ
.contact {
  background-color: #000;
  text-align: center;
  padding: 30px 0 100px 0;

  &__ttl {
    font-size: 26px;
    color: #fff;
    border-top: 1px solid #664806;
    border-bottom: 1px solid #664806;
    width: 53%;
    margin: 0 auto;
    padding: 10px 0;
    margin-bottom: 20px;
  }

  &__tel {
    font-size: 50px;
    &-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-num {
      & > img {
        position: relative;
        bottom: 12px;
        right: 8px;
      }

      & a {
        color: #fff;
        text-decoration: none;
      }
    }

    &-date {
      text-align: left;
      margin-left: 30px;
      color: #fff;
      font-size: 14px;
    }
  }
}

.footer {
  background-color: #664806;
  color: #fff;
  padding: 37px 0;
  position: relative;

  &__scroll {
    position: fixed;
    bottom: 220px;
    right: 30px;
  }

  &__inner {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__logo {
    border-right: 1px solid #fff;
    padding-right: 40px;
    & P {
      font-size: 12px;
    }
  }

  &__txt {
    padding-left: 40px;
    font-size: 14px;
  }

  &__left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__right {
    margin-top: 100px;
    font-size: 12px;
  }
}
