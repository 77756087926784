@charset "UTF-8";
.appear.up .item {
  transform: translateY(6px); }

.appear.down .item {
  transform: translateY(-6px); }

.appear.left .item {
  transform: translateX(40px); }

.appear.right .item {
  transform: translateX(-40px); }

.appear .item {
  transition: all 1.8s;
  opacity: 0; }

.appear.inview .item {
  opacity: 1;
  transform: none; }
  .appear.inview .item:nth-child(1) {
    transition-delay: 0.1s; }
  .appear.inview .item:nth-child(2) {
    transition-delay: 0.2s; }
  .appear.inview .item:nth-child(3) {
    transition-delay: 0.3s; }
  .appear.inview .item:nth-child(4) {
    transition-delay: 0.4s; }
  .appear.inview .item:nth-child(5) {
    transition-delay: 0.5s; }
  .appear.inview .item:nth-child(6) {
    transition-delay: 0.6s; }
  .appear.inview .item:nth-child(7) {
    transition-delay: 0.7s; }
  .appear.inview .item:nth-child(8) {
    transition-delay: 0.8s; }
  .appear.inview .item:nth-child(9) {
    transition-delay: 0.9s; }
  .appear.inview .item:nth-child(10) {
    transition-delay: 1s; }

.btn-white {
  background: white;
  width: 100% !important;
  color: black !important;
  height: 60px !important;
  line-height: 1.6 !important; }
  .btn-white > img {
    position: relative;
    top: 7px;
    right: 10px; }

.button {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 10px;
  cursor: pointer;
  transition: .3s;
  z-index: 30;
  /*デフォルトで非表示にする*/
  opacity: 0;
  visibility: hidden; }

/*このクラスが付与されると表示する*/
.active {
  opacity: 1;
  visibility: visible; }

/*このクラスが付与されると表示する*/
.absolute {
  position: absolute;
  top: -70px;
  bottom: auto; }

.cover-slide {
  position: relative;
  overflow: hidden; }
  .cover-slide::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eaebe6;
    opacity: 0; }
  .cover-slide.inview::after {
    opacity: 1;
    animation-name: kf-cover-slide;
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both; }

@keyframes kf-cover-slide {
  0% {
    transform-origin: left;
    transform: scaleX(0); }
  50% {
    transform-origin: left;
    transform: scaleX(1); }
  50.1% {
    transform-origin: right;
    transform: scaleX(1); }
  100% {
    transform-origin: right;
    transform: scaleX(0); } }

.img-zoom, .bg-img-zoom {
  opacity: 0; }
  .inview .img-zoom, .inview .bg-img-zoom {
    opacity: 1;
    transition: transform 0.3s ease;
    animation-name: kf-img-show;
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none; }
    .inview .img-zoom:hover, .inview .bg-img-zoom:hover {
      transform: scale(1.05); }

@keyframes kf-img-show {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  50.1% {
    opacity: 1;
    transform: scale(1.5); }
  100% {
    opacity: 1; } }

.hover-darken::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: background-color 0.3s ease;
  pointer-events: none;
  animation-name: kf-img-show;
  animation-duration: 1.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none; }

.hover-darken:hover::before {
  background-color: rgba(0, 0, 0, 0.4); }

.bg-img-zoom {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%; }

.img-bg50 {
  position: relative; }
  .img-bg50::before {
    display: block;
    content: "";
    padding-top: 50%; }

.img-bg60 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%; }
  .img-bg60::after {
    display: block;
    content: "";
    padding-top: 60%; }

.mobile-menu {
  position: fixed;
  right: -60px;
  top: 60px;
  width: 300px; }
  .mobile-menu__nav {
    padding-top: 75px; }
  .mobile-menu__ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .mobile-menu__li {
    list-style: none;
    width: 100%;
    height: 40px;
    text-align: center;
    position: relative;
    margin-bottom: 10px; }
    .mobile-menu__li > a {
      text-decoration: none;
      color: #fff;
      width: 90%;
      line-height: 2;
      margin: 0 auto; }
    .mobile-menu__li:after {
      content: "";
      background-position: center;
      background-size: contain;
      background-repeat: repeat;
      display: inline-block;
      width: 90%;
      height: 2px;
      vertical-align: middle; }
  .mobile-menu__link {
    display: block;
    height: 100%;
    line-height: 60px;
    color: #534741;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold; }
  .mobile-menu__btn {
    background-color: unset;
    border: none;
    outline: none !important;
    cursor: pointer;
    border: 1px solid white;
    padding: 12px;
    border-radius: 50%; }
    .mobile-menu__btn > span {
      background-color: #fff;
      width: 28px;
      height: 2px;
      display: block;
      margin-bottom: 9px;
      transition: transform 0.7s; }
      .mobile-menu__btn > span:nth-child(3) {
        margin-bottom: 0;
        width: 15px; }
  .mobile-menu__cover {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    background: rgba(8, 8, 8, 0.9);
    transition: all 0.8s;
    overflow-y: scroll; }

.menu-open .mobile-menu__cover {
  height: 100%;
  transition: all 0.8s;
  overflow-y: scroll; }

.menu-open .mobile-menu__item {
  transform: none;
  opacity: 1; }
  .menu-open .mobile-menu__item:nth-child(1) {
    transition-delay: 0.07s; }
  .menu-open .mobile-menu__item:nth-child(2) {
    transition-delay: 0.14s; }
  .menu-open .mobile-menu__item:nth-child(3) {
    transition-delay: 0.21s; }
  .menu-open .mobile-menu__item:nth-child(4) {
    transition-delay: 0.28s; }
  .menu-open .mobile-menu__item:nth-child(5) {
    transition-delay: 0.35s; }

.menu-open .mobile-menu__btn > span {
  background-color: #fff; }
  .menu-open .mobile-menu__btn > span:nth-child(1) {
    transition-delay: 70ms;
    transform: translateY(11px) rotate(135deg);
    width: 28px; }
  .menu-open .mobile-menu__btn > span:nth-child(2) {
    transition-delay: 0s;
    transform: translateX(-18px) scaleX(0);
    width: 28px; }
  .menu-open .mobile-menu__btn > span:nth-child(3) {
    transition-delay: 140ms;
    transform: translateY(-11px) rotate(-135deg);
    width: 28px; }

.site__title > a > img {
  position: relative;
  z-index: 1000; }

.main__logo {
  position: fixed;
  top: 20px;
  left: 60px;
  z-index: 100; }

/*
ハンバーガーメニュー
解説は、「中級編：ストアサイト（インテリア）」のCSS参照
*/
#navi {
  display: block;
  position: fixed;
  left: -300px;
  width: 30%;
  height: 100%;
  color: #fff;
  padding: 60px 25px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 58%, rgba(0, 0, 0, 0.8) 62%, rgba(0, 0, 0, 0.2) 85%, rgba(0, 0, 0, 0) 100%);
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s;
  z-index: 20;
  opacity: 0; }

.open #navi {
  left: 0;
  opacity: 1; }

#navi ul {
  margin: 0;
  padding: 0; }

#navi ul.nav-menu {
  margin-bottom: 60px;
  margin-top: 30%; }

#navi ul.nav-menu li {
  list-style: none;
  position: relative;
  margin-left: 100px;
  padding: 20px 0; }

#navi ul.nav-menu li > a {
  position: relative;
  font-size: 14px;
  color: #fff;
  font-family: 'Hina Mincho', serif; }

#navi ul.nav-menu li > a:hover {
  text-decoration: none;
  opacity: 0.7; }

#navi ul.nav-menu li > a::before {
  content: '';
  position: absolute;
  top: 12px;
  left: -17px;
  width: 10px;
  height: 1px;
  background-color: #fff; }

#navi ul.nav-sns li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: 90px; }

#navi ul.nav-sns li > a {
  color: #fff; }

#navi ul.nav-sns li > a:hover {
  text-decoration: none;
  opacity: 0.7; }

#navi ul.nav-sns li > a > img {
  position: relative;
  bottom: 3px;
  right: 10px; }

.toggle_btn {
  display: block;
  position: fixed;
  top: 120px;
  left: 60px;
  width: 50px;
  height: 50px;
  transition: all 0.5s;
  cursor: pointer;
  z-index: 20;
  border: 1px solid #fff;
  border-radius: 50%; }

.toggle_btn span {
  display: block;
  position: absolute;
  left: 11px;
  width: 25px;
  height: 1px;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.5s; }

.toggle_btn span:nth-child(1) {
  top: 12px; }

.toggle_btn span:nth-child(2) {
  top: 24px; }

.toggle_btn span:nth-child(3) {
  bottom: 12px;
  width: 13px; }

.open .toggle_btn span {
  background-color: #fff;
  width: 25px; }

.open .toggle_btn span:nth-child(1) {
  -webkit-transform: translateY(10px) rotate(-315deg);
  transform: translateY(10px) rotate(-315deg);
  top: 14px; }

.open .toggle_btn span:nth-child(2) {
  opacity: 0; }

.open .toggle_btn span:nth-child(3) {
  -webkit-transform: translateY(-10px) rotate(315deg);
  transform: translateY(-10px) rotate(315deg);
  width: 25px; }

#mask {
  display: none;
  transition: all 0.5s; }

.open #mask {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 0.8;
  z-index: 10;
  cursor: pointer; }

/*========= LoadingのためのCSS ===============*/
/* Loading背景画面設定　*/
#splash {
  /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #fff;
  text-align: center;
  color: #fff; }

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

/* Loading アイコンの大きさ設定　*/
#splash_logo img {
  width: 260px; }

/* fadeUpをするアイコンの動き */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0; }

@keyframes fadeUpAnime {
  from {
    opacity: 0; }
  to {
    opacity: 1;
    transform: translateY(0); } }

html {
  scroll-behavior: smooth; }

body {
  font-family: "Shippori Mincho", serif;
  letter-spacing: 0.05em; }

img {
  max-width: 100%;
  vertical-align: bottom; }

.fo-ttl, .ttl, .menu-ttl__wrap {
  font-family: "Hina Mincho", serif; }

.mr-lr {
  margin-right: 100px; }

.content-width, .news__inner {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px; }

#global-container {
  overflow: hidden; }

.mobile-menu__btn {
  display: none; }

.header {
  display: none; }

.scroll {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; }
  .scroll > p {
    font-size: 12px;
    color: #fff;
    position: relative; }
    .scroll > p::after {
      content: "";
      position: absolute;
      top: 60px;
      left: -30%;
      width: 60px;
      height: 1px;
      background-color: #fff;
      transform: rotate(90deg);
      /*線の動き1.4秒かけて動く。永遠にループ*/
      animation: pathmove 1.4s ease-in-out infinite; }

/*高さ・位置・透過が変化して線が上から下に動く*/
@keyframes pathmove {
  0% {
    height: 0;
    top: 55px;
    opacity: 0; }
  30% {
    height: 1px;
    opacity: 1; }
  100% {
    height: 0;
    top: 80px;
    opacity: 0; } }

.video-container {
  height: 100vh;
  position: relative; }

video {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }

#news,
#about,
#shef,
#shop {
  background-color: #000; }

.ttl {
  width: 90px; }
  .ttl__bar {
    text-align: center; }
  .ttl__blk {
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 10px; }
  .ttl__ja {
    writing-mode: vertical-lr;
    color: #fff;
    font-size: 30px;
    letter-spacing: 0.05em; }
  .ttl__en {
    writing-mode: vertical-lr;
    color: #664806;
    font-size: 20px;
    letter-spacing: 0.35em; }
  .ttl__sp {
    display: none; }

.news {
  padding: 190px 0; }
  .news__blk {
    display: flex;
    justify-content: space-evenly; }
  .news__ul {
    padding: 0;
    margin: 0; }
  .news__li {
    list-style: none;
    color: #fff;
    margin-bottom: 35px;
    font-size: 13px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px; }
    .news__li > a {
      color: #fff; }
      .news__li > a:hover {
        text-decoration: none;
        color: #fff; }
  .news__more {
    text-align: right;
    width: 90%; }
    .news__more > a {
      color: #fff;
      position: relative; }
      .news__more > a:hover {
        color: #fff;
        text-decoration: none; }
      .news__more > a::after {
        content: "";
        display: block;
        background-image: url(../images/news_yajirushi.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 90px; }

.about {
  background-image: url(../images/about_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 600px -200px;
  padding-bottom: 200px; }
  .about__inner {
    width: 60%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 100px 0; }
  .about__blk {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 5%; }
  .about__ttl-sp {
    display: none; }
  .about__txt {
    height: 370px; }
    .about__txt > p {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0; }

.shef {
  background-image: url(../images/chef_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -360px 0px; }
  .shef::after {
    content: "";
    display: block;
    padding-top: 10%; }
  .shef__inner {
    width: 70%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse; }
  .shef__ttl-sp {
    display: none;
    margin-top: 70px; }
  .shef__blk {
    width: 60%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 300px;
    position: relative;
    bottom: 100px; }
  .shef__txt {
    height: 370px; }
    .shef__txt > p {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0; }
      .shef__txt > p > span {
        display: block;
        text-align: end; }
        .shef__txt > p > span .br {
          display: none; }

.slick-slide > img {
  width: 100%;
  height: 100%; }

#farm {
  background-image: url(../images/farm_bg_img.png);
  background-repeat: no-repeat;
  background-size: cover; }

.farm {
  background-image: url(../images/farm_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 500px -100px;
  padding-bottom: 200px; }
  .farm__inner {
    width: 70%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 100px 0; }
  .farm__blk {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 110px; }
  .farm__ttl-sp {
    display: none; }
  .farm__txt {
    height: 370px;
    display: flex;
    flex-direction: row-reverse; }
    .farm__txt > p:nth-child(1) {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0; }
    .farm__txt > p:nth-child(2) {
      color: #fff;
      writing-mode: vertical-rl;
      line-height: 2;
      font-size: 14px;
      margin: 0;
      text-align: end; }

.menu-price {
  font-size: 18px !important;
  text-align: left; }

.menu-ttl__wrap {
  text-align: center; }

.menu-ttl__ja {
  writing-mode: vertical-lr;
  color: #fff;
  font-size: 30px;
  position: relative;
  right: 2px;
  top: 6px;
  letter-spacing: 0.05em; }

.menu-ttl__en {
  writing-mode: vertical-lr;
  color: #664806;
  font-size: 20px;
  position: relative;
  right: 3px;
  bottom: 45px;
  letter-spacing: 0.35em; }

.menu-ttl__sp {
  display: none; }

.menu {
  background-image: url(../images/menu_bg_wrap.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: -700px; }
  .menu__ttl {
    text-align: center; }
    .menu__ttl-sp {
      display: none; }
  .menu__flex {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0); }
  .menu__left-ttl__ja {
    font-size: 26px;
    color: #664806;
    margin-top: 10px;
    display: block; }
  .menu__left > p {
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 14px; }
  .menu__right > h2 {
    font-size: 190px;
    color: #fff;
    margin-bottom: 0; }
  .menu__des-flex {
    width: 80%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 80px;
    position: relative;
    z-index: 1; }
  .menu__des-left {
    writing-mode: vertical-rl;
    height: 370px;
    text-align: left;
    line-height: 2; }
    .menu__des-left > p {
      margin: 0;
      font-size: 14px; }
  .menu__des-right {
    width: 70%; }
  .menu__img-inner {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto -300px; }
  .menu__img-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -600px; }
  .menu__img-left {
    position: relative;
    left: 30px; }
  .menu__img-right {
    margin-top: 800px;
    position: relative;
    right: 30px;
    z-index: 1; }

.lunch__menu-bg {
  background-image: url(../images/lunch_bg_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 1081px;
  padding: 100px 0 0 0; }

.dinner__menu-bg {
  background-image: url(../images/lunch_bg_img02.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  padding-top: 500px; }

.dinner {
  background-image: url(../images/menu_bg_wrap.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .dinner__ttl {
    text-align: center; }
    .dinner__ttl-sp {
      display: none; }
  .dinner__flex {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center; }
  .dinner__left {
    text-align: right; }
    .dinner__left > p {
      margin-bottom: 0;
      margin-top: 15px;
      font-size: 14px; }
  .dinner__right > h2 {
    font-size: 190px;
    color: #fff;
    margin-bottom: 0; }
  .dinner__des-flex {
    width: 80%;
    max-width: 1100px;
    margin: 0 auto -50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    position: relative;
    bottom: 80px;
    position: relative;
    z-index: 1; }
  .dinner__des-left {
    writing-mode: vertical-rl;
    height: 370px;
    text-align: left;
    line-height: 2; }
    .dinner__des-left > p {
      margin: 0;
      font-size: 14px; }
  .dinner__des-right {
    width: 70%; }
  .dinner__img-inner {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto; }
  .dinner__img-flex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: -600px; }
  .dinner__img-left {
    position: relative;
    right: 30px; }
  .dinner__img-right {
    margin-top: 800px;
    position: relative;
    z-index: 1;
    left: 30px; }
  .dinner__img-bgbottom {
    background-image: url(../images/dinner_bg_img02.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    bottom: 600px; }
    .dinner__img-bgbottom::after {
      content: "";
      display: block;
      padding-top: 70%; }

.shop {
  background-image: url(../images/shop_main_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 200px 0;
  padding-bottom: 300px; }
  .shop__inner {
    width: 70%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row; }
  .shop__blk {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 300px; }
  .shop__ttl-sp {
    display: none; }
  .shop__txt > p:nth-child(1) {
    color: #fff;
    line-height: 2;
    font-size: 14px;
    margin: 0;
    border-bottom: 1px solid #fff;
    padding-bottom: 30px; }
  .shop__txt > p:nth-child(2) {
    color: #fff;
    line-height: 2;
    font-size: 13px;
    margin: 0;
    padding-top: 30px; }
  .shop__ttl {
    margin-right: 100px; }
  .shop__span {
    font-size: 18px; }

.reserve {
  background-color: #000;
  text-align: center;
  padding: 100px 0; }

.contact {
  background-color: #000;
  text-align: center;
  padding: 30px 0 100px 0; }
  .contact__ttl {
    font-size: 26px;
    color: #fff;
    border-top: 1px solid #664806;
    border-bottom: 1px solid #664806;
    width: 53%;
    margin: 0 auto;
    padding: 10px 0;
    margin-bottom: 20px; }
  .contact__tel {
    font-size: 50px; }
    .contact__tel-wrap {
      display: flex;
      justify-content: center;
      align-items: center; }
    .contact__tel-num > img {
      position: relative;
      bottom: 12px;
      right: 8px; }
    .contact__tel-num a {
      color: #fff;
      text-decoration: none; }
    .contact__tel-date {
      text-align: left;
      margin-left: 30px;
      color: #fff;
      font-size: 14px; }

.footer {
  background-color: #664806;
  color: #fff;
  padding: 37px 0;
  position: relative; }
  .footer__scroll {
    position: fixed;
    bottom: 220px;
    right: 30px; }
  .footer__inner {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .footer__logo {
    border-right: 1px solid #fff;
    padding-right: 40px; }
    .footer__logo P {
      font-size: 12px; }
  .footer__txt {
    padding-left: 40px;
    font-size: 14px; }
  .footer__left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .footer__right {
    margin-top: 100px;
    font-size: 12px; }

@media screen and (max-width: 1280px) {
  .about {
    background-position: 300px -200px; }
  .shop {
    background-position: -240px 0; } }

@media screen and (max-width: 1100px) {
  .farm__blk {
    width: 54%; } }

@media screen and (max-width: 1700px) {
  .farm {
    background-position: 200px 0; }
  #navi ul.nav-menu {
    margin-top: 130px;
    margin-bottom: 30px; }
  #navi ul.nav-menu li {
    padding: 15px 0; } }

@media screen and (max-width: 960px) {
  .mobile-menu__btn {
    display: block; }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: #000;
    display: flex;
    align-items: center;
    z-index: 100; }
    .header.triggered {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 10px 0 25px -10px rgba(0, 0, 0, 0.5); }
    .header__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      margin: 0 auto; }
    .header__ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none; }
    .header__li {
      margin-left: 20px; }
      .header__li > a {
        color: black;
        text-decoration: none !important;
        text-transform: uppercase; }
    .header .logo {
      width: 60px; }
  video {
    height: 800px; }
  .video__wrap {
    display: none; }
  .video-container {
    height: 800px; }
  #news {
    padding: 80px 0 0 0; }
  .news__blk {
    flex-direction: column; }
  .news__ul {
    margin-top: 50px; }
  .news__more {
    text-align: center; }
  .mb-lr {
    margin: 90px auto 50px !important; }
  .ttl {
    display: none; }
    .ttl__sp {
      width: 200px;
      display: block;
      text-align: center;
      margin: 0 auto;
      font-family: "Hina Mincho", serif; }
      .ttl__sp-ja {
        color: #fff;
        font-size: 30px;
        position: relative;
        top: 32px;
        letter-spacing: 0.05em; }
      .ttl__sp-en {
        color: #664806;
        font-size: 20px;
        position: relative;
        bottom: 42px;
        letter-spacing: 0.35em; }
  .about {
    background-position: 0 400px;
    background-size: contain; }
    .about__ttl {
      display: none; }
      .about__ttl-sp {
        display: block;
        text-align: center; }
    .about__blk {
      flex-direction: column-reverse;
      width: 90%;
      margin-left: 0; }
    .about__txt > p {
      writing-mode: inherit;
      margin-top: 60px; }
    .about__inner {
      width: 100%;
      justify-content: center;
      padding: 50px 0; }
  .shef {
    background-position: 0 400px;
    background-size: contain; }
    .shef__ttl {
      display: none; }
      .shef__ttl-sp {
        display: block;
        text-align: center;
        margin-top: -100px;
        padding-top: 180px; }
    .shef__inner {
      width: 100%;
      justify-content: center;
      padding: 0 0 100px 0; }
    .shef__blk {
      flex-direction: column-reverse;
      width: 90%;
      margin-top: 0;
      position: relative;
      bottom: 0; }
    .shef__txt > p {
      writing-mode: inherit;
      margin-top: 60px; }
  .farm {
    background-position: 0 400px;
    background-size: contain; }
    .farm__ttl {
      display: none; }
      .farm__ttl-sp {
        display: block;
        text-align: center;
        margin-top: -100px;
        padding-top: 180px; }
    .farm__inner {
      width: 100%;
      justify-content: center;
      padding: 0 0 100px 0; }
    .farm__blk {
      flex-direction: column-reverse;
      width: 90%;
      margin-top: 0; }
    .farm__txt {
      flex-direction: column; }
      .farm__txt > p:nth-child(1) {
        writing-mode: inherit;
        margin-top: 60px; }
      .farm__txt > p:nth-child(2) {
        writing-mode: inherit; }
  .menu-ttl {
    display: none; }
    .menu-ttl__sp {
      display: block; }
      .menu-ttl__sp-en {
        font-size: 20px;
        color: #664806;
        margin-top: 5px; }
      .menu-ttl__sp-ja {
        font-size: 30px; }
  .menu__right {
    margin-left: 30%; }
    .menu__right > h2 {
      font-size: 160px;
      position: relative;
      top: 100px; }
  .menu__left {
    margin-right: 30%; }
  .menu__ttl {
    display: none; }
    .menu__ttl-sp {
      display: block;
      text-align: center; }
  .menu__des-flex {
    margin: 100px auto 0; }
  .menu__flex {
    flex-direction: column-reverse; }
  .menu__des-flex {
    flex-direction: column-reverse;
    width: 100%;
    margin: 130px 0 0 0; }
  .menu__des-right {
    width: 100%;
    margin-bottom: 70px; }
    .menu__des-right > img {
      width: 100%; }
  .menu__des-left {
    writing-mode: inherit;
    height: 0; }
    .menu__des-left > p {
      width: 90%;
      margin: -40px auto; }
  .menu__img-inner {
    margin: 100px auto 0; }
  .menu__img-flex {
    flex-direction: column;
    margin-top: 0; }
  .menu__img-right {
    margin-top: 0;
    right: 0; }
  .menu__img-left {
    left: 0;
    margin-bottom: 30px; }
  .lunch__menu-bg {
    height: 750px; }
  .dinner__menu-bg {
    padding-top: 200px; }
  .dinner__des-flex {
    flex-direction: column-reverse;
    width: 100%; }
  .dinner__des-left {
    writing-mode: inherit; }
    .dinner__des-left > p {
      width: 90%;
      margin: 20px auto 0; }
  .dinner__des-right {
    width: 100%;
    margin-top: 30px; }
    .dinner__des-right > img {
      width: 100%; }
  .dinner__flex {
    flex-direction: column-reverse; }
  .dinner__right {
    margin-left: 30%; }
    .dinner__right > h2 {
      font-size: 160px; }
  .dinner__left {
    width: 100%;
    position: relative;
    z-index: 10;
    bottom: 90px;
    text-align: left; }
  .dinner__ttl {
    display: none; }
    .dinner__ttl-sp {
      display: block; }
  .dinner__img-inner {
    margin: 400px auto 200px; }
  .dinner__img-flex {
    flex-direction: column; }
  .dinner__img-right {
    margin-top: 0;
    left: 0; }
  .dinner__img-left {
    right: 0;
    margin-bottom: 30px; }
  .dinner__img-bgbottom::after {
    content: "";
    display: block;
    padding-top: 100%; }
  .shop {
    background-position: 0 400px;
    background-size: contain;
    padding-bottom: 200px; }
    .shop__ttl {
      display: none; }
      .shop__ttl-sp {
        display: block;
        text-align: center;
        margin-bottom: 60px;
        margin-top: -100px;
        padding-top: 180px; }
    .shop__blk {
      flex-direction: column-reverse;
      margin-top: 0; }
    .shop__inner {
      width: 90%;
      justify-content: center; }
    .shop__span {
      margin-bottom: 10px; }
    .shop__txt > p:nth-child(1) {
      text-align: center; }
  .contact {
    padding: 70px 0 100px 0; }
    .contact__ttl {
      font-size: 20px;
      width: 80%; }
    .contact__tel-wrap {
      flex-direction: column; }
    .contact__tel-date {
      text-align: center;
      margin-left: 0;
      margin-top: 20px; }
    .contact__time {
      margin-bottom: 8px; }
  .footer {
    padding: 26px 0 37px 0; }
    .footer__inner {
      flex-direction: column; }
    .footer__left {
      flex-direction: column; }
    .footer__logo {
      padding-right: 0;
      border-right: 0;
      border-bottom: 1px solid #fff;
      text-align: center;
      margin-bottom: 30px;
      width: 100%;
      padding-bottom: 20px; }
    .footer__txt {
      padding-left: 0;
      text-align: center;
      line-height: 2; }
    .footer__right {
      margin-top: 30px;
      text-align: center; } }

@media screen and (max-width: 780px) {
  video {
    height: 400px; }
  .video-container {
    height: 400px; }
  .farm {
    background-position: bottom; }
    .farm__inner {
      padding: 0 0 60px 0; }
  .menu__right > h2 {
    font-size: 110px; }
  .menu__left {
    position: relative;
    z-index: 1; }
  .dinner__right > h2 {
    font-size: 110px; }
  .shop {
    background-position: bottom; }
    .shop__inner {
      margin: 0 auto 100px; } }

@media (max-width: 600px) {
  .menu__left {
    width: 100%;
    position: relative;
    z-index: 1;
    margin-right: 0; }
  .menu__right > h2 {
    top: 50px; }
  .lunch__menu-bg {
    height: 500px; }
  .dinner__img-bgbottom {
    bottom: 700px; }
  .dinner__img-inner {
    margin: 400px auto 500px; } }

@media screen and (max-width: 480px) {
  .about {
    padding-bottom: 0; }
  .shef {
    background-position: bottom; }
    .shef::after {
      content: "";
      display: block;
      padding-top: 50%; }
  .menu__right > h2 {
    font-size: 90px; }
  .menu__left {
    position: relative;
    z-index: 1; }
  .menu__des-right {
    width: 460px; }
  .dinner__right {
    margin-left: 15%;
    margin-bottom: 40px; }
    .dinner__right > h2 {
      font-size: 90px; }
  .dinner__des-right {
    width: 460px; }
  .dinner__ttl-sp {
    width: 38%; }
  iframe {
    height: 200px; } }
