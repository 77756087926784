@media (max-width: 600px) {
   .menu {
     &__left {
       width: 100%;
       position: relative;
       z-index: 1;
      margin-right: 0;
     }

     &__right {
       & > h2 {
         top: 50px;
       }
     }
   }
   .lunch {
     &__menu {
       &-bg {
         height: 500px;
       }
     }
   }
   .dinner {
     &__img {
       &-bgbottom {
         bottom: 700px;
       }
       &-inner {
         margin: 400px auto 500px;
       }
     }
   }
  }