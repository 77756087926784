.btn {
    &-white {
        background: white;
    width: 100% !important;
    color: black !important;
    height: 60px !important;
    line-height: 1.6 !important;

    & > img {
        position: relative;
        top: 7px;
        right: 10px;
    }
    }
}

.button{
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 10px;
    cursor: pointer;
    transition: .3s;
    z-index:30;
    
    /*デフォルトで非表示にする*/
    opacity: 0;
    visibility: hidden;
  }
  
  /*このクラスが付与されると表示する*/
  .active{
    opacity: 1;
    visibility: visible;
  }
  
  /*このクラスが付与されると表示する*/
  .absolute{
    position: absolute;
    top: -70px;
    bottom: auto;
  }