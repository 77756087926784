@media screen and (max-width: 960px) {
  .mobile-menu {
    &__btn {
      display: block;
    }
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: #000;
    display: flex;
    align-items: center;
    z-index: 100;

    &.triggered {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 10px 0 25px -10px rgba(0, 0, 0, 0.5);
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      margin: 0 auto;
    }

    &__ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &__li {
      margin-left: 20px;

      & > a {
        color: $cBlack;
        text-decoration: none !important;
        text-transform: uppercase;
      }
    }

    & .logo {
      width: 60px;
    }
  }

  video {
    height: 800px;
  }
  .video {
    &__wrap {
      display: none;
    }

    &-container {
      height: 800px;
    }
  }
  #news {
    padding: 80px 0 0 0;
  }
  .news {
    &__blk {
      flex-direction: column;
    }
    &__ul {
      margin-top: 50px;
    }
    &__more {
      text-align: center;
    }
  }

  .mb-lr {
    margin: 90px auto 50px !important;
  }
  .ttl {
    display: none;
    &__sp {
      width: 200px;
      display: block;
      text-align: center;
      margin: 0 auto;
      font-family: "Hina Mincho", serif;
      &-ja {
        color: #fff;
        font-size: 30px;
        position: relative;
        top: 32px;
        letter-spacing: 0.05em;
      }
      &-en {
        color: #664806;
        font-size: 20px;
        position: relative;
        bottom: 42px;
        letter-spacing: 0.35em;
      }
    }
  }

  .about {
    background-position: 0 400px;
    background-size: contain;

    &__ttl {
      display: none;

      &-sp {
        display: block;
        text-align: center;
      }
    }
    &__blk {
      flex-direction: column-reverse;
      width: 90%;
      margin-left: 0;
    }

    &__txt {
      & > p {
        writing-mode: inherit;
        margin-top: 60px;
      }
    }
    &__inner {
      width: 100%;
      justify-content: center;
      padding: 50px 0;
    }
  }
  .shef {
    background-position: 0 400px;
    background-size: contain;
    &__ttl {
      display: none;

      &-sp {
        display: block;
        text-align: center;
        margin-top: -100px;
        padding-top: 180px;
      }
    }

    &__inner {
      width: 100%;
      justify-content: center;
      padding: 0 0 100px 0;
    }

    &__blk {
      flex-direction: column-reverse;
      width: 90%;
      margin-top: 0;
      position: relative;
      bottom: 0;
    }

    &__txt {
      & > p {
        writing-mode: inherit;
        margin-top: 60px;
      }
    }
  }

  .farm {
    background-position: 0 400px;
    background-size: contain;

    &__ttl {
      display: none;

      &-sp {
        display: block;
        text-align: center;
        margin-top: -100px;
        padding-top: 180px;
      }
    }

    &__inner {
      width: 100%;
      justify-content: center;
      padding: 0 0 100px 0;
    }

    &__blk {
      flex-direction: column-reverse;
      width: 90%;
      margin-top: 0;
    }

    &__txt {
      flex-direction: column;
      & > p:nth-child(1) {
        writing-mode: inherit;
        margin-top: 60px;
      }
      & > p:nth-child(2) {
        writing-mode: inherit;
      }
    }
  }

  // .slick-slide > img {
  //   height: 180px;
  //   object-fit: cover;
  // }
  .menu-ttl {
    display: none;
    &__sp {
      display: block;

      &-en {
        font-size: 20px;
        color: #664806;
        margin-top: 5px;
      }
      &-ja {
        font-size: 30px;
      }
    }
  }

  .menu {
    
    &__right {
      margin-left: 30%;
      & > h2 {
        font-size: 160px;
        position: relative;
        top: 100px;
      }
    }

    &__left {
      margin-right: 30%;
    }

    &__ttl {
      display: none;

      &-sp {
        display: block;
        text-align: center;
      }
    }
    &__des {
      &-flex {
        margin: 100px auto 0;
      }
    }

    &__flex {
      flex-direction: column-reverse;
    }

    &__des {
      &-flex {
        flex-direction: column-reverse;
        width: 100%;
        margin: 130px 0 0 0;
      }
      &-right {
        width: 100%;
        margin-bottom: 70px;
        & > img {
          width: 100%;
        }
      }

      &-left {
        writing-mode: inherit;
        height: 0;
        & > p {
          width: 90%;
          margin: -40px auto;
        }
      }
    }

    &__img {
      &-inner {
        margin: 100px auto 0;
      }
      &-flex {
        flex-direction: column;
        margin-top: 0;
      }
      &-right {
        margin-top: 0;
        right: 0;
      }
      &-left {
        left: 0;
        margin-bottom: 30px;
      }
    }
  }

  .lunch {
    &__menu {
      &-bg {
        height: 750px;
      }
    }
  }

  .dinner {
    &__menu {
      &-bg {
        padding-top: 200px;
      }
    }

    &__des {
      &-flex {
        flex-direction: column-reverse;
        width: 100%;
      }
      &-left {
        writing-mode: inherit;
        & > p {
          width: 90%;
          margin: 20px auto 0;
        }
      }
      &-right {
        width: 100%;
        margin-top: 30px;
        & > img {
          width: 100%;
        }
      }
    }

    &__flex {
      flex-direction: column-reverse;
    }

    &__right {
      margin-left: 30%;
      & > h2 {
        font-size: 160px;
      }
    }

    &__left {
      width: 100%;
      position: relative;
      z-index: 10;
      bottom: 90px;
      text-align: left;
    }

    &__ttl {
      display: none;
      &-sp {
        display: block;
      }
    }

    &__img {
      &-inner {
        margin: 400px auto 200px;
      }
      &-flex {
        flex-direction: column;
      }
      &-right {
        margin-top: 0;
        left: 0;
      }
      &-left {
        right: 0;
        margin-bottom: 30px;
      }
      &-bgbottom {
        &::after {
          content: "";
          display: block;
          padding-top: 100%;
        }
      }
    }
  }

  .shop {
    background-position: 0 400px;
    background-size: contain;
    padding-bottom: 200px;
    &__ttl {
      display: none;
      &-sp {
        display: block;
        text-align: center;
        margin-bottom: 60px;
        margin-top: -100px;
        padding-top: 180px;
      }
    }
    &__blk {
      flex-direction: column-reverse;
      margin-top: 0;
    }
    &__inner {
      width: 90%;
      justify-content: center;
    }
    &__span {
      margin-bottom: 10px;
    }

    &__txt {
      & > p:nth-child(1) {
        text-align: center;
      }
      & > p:nth-child(2) {
      }
    }
  }

  .contact {
    padding: 70px 0 100px 0;
    &__ttl {
      font-size: 20px;
      width: 80%;
    }
    &__tel {
      &-wrap {
        flex-direction: column;
      }
      &-date {
        text-align: center;
        margin-left: 0;
        margin-top: 20px;
      }
    }
    &__time {
      margin-bottom: 8px;
    }
  }

  .footer {
    padding: 26px 0 37px 0;
    &__inner {
      flex-direction: column;
    }
    &__left {
      flex-direction: column;
    }
    &__logo {
      padding-right: 0;
      border-right: 0;
      border-bottom: 1px solid #fff;
      text-align: center;
      margin-bottom: 30px;
      width: 100%;
      padding-bottom: 20px;
    }
    &__txt {
      padding-left: 0;
      text-align: center;
      line-height: 2;
    }
    &__right {
      margin-top: 30px;
      text-align: center;
    }
  }
}
