.mobile-menu {
  position: fixed;
  right: -60px;
  top: 60px;
  width: 300px;

  &__nav {
    padding-top: 75px;
  }
  &__ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__li {
    list-style: none;
    width: 100%;
    height: 40px;
    text-align: center;
    position: relative;
    margin-bottom:10px;

    & > a {
      text-decoration: none;
      color: #fff;
      width: 90%;
      line-height: 2;
      margin: 0 auto;
    }

    &:after {
      content: "";
      // background-image: url(../image/mobile-menu_underline.png);
      background-position: center;
      background-size: contain;
      background-repeat: repeat;
      display: inline-block;
      width: 90%;
      height: 2px;
      vertical-align: middle;
    }
  }
  &__link {
    display: block;
    height: 100%;
    line-height: 60px;
    color: #534741;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
  }

  &__btn {
    background-color: unset;
    border: none;
    outline: none !important;
    cursor: pointer;
    // display: none;
    border: 1px solid white;
    padding: 12px;
    border-radius: 50%;

    & > span {
      background-color: #fff;
      width: 28px;
      height: 2px;
      display: block;
      margin-bottom: 9px;
      transition: transform 0.7s;

      &:nth-child(3) {
        margin-bottom: 0;
        width: 15px;
      }
    }
  }

  &__cover {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    background: rgba(8, 8, 8, 0.9);
    transition: all 0.8s;
    overflow-y: scroll;
  }
}

.menu-open {
  & .mobile-menu {
    &__cover {
      height: 100%;
      transition: all 0.8s;
      overflow-y: scroll;
    }

    &__item {
      transform: none;
      opacity: 1;

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.07s;
        }
      }
    }

    &__btn {
      & > span {
        background-color: #fff;

        &:nth-child(1) {
          transition-delay: 70ms;
          transform: translateY(11px) rotate(135deg);
          width: 28px;
        }
        &:nth-child(2) {
          transition-delay: 0s;
          transform: translateX(-18px) scaleX(0);
          width: 28px;
        }
        &:nth-child(3) {
          transition-delay: 140ms;
          transform: translateY(-11px) rotate(-135deg);
          width: 28px;
        }
      }
    }
  }
}
