/*========= LoadingのためのCSS ===============*/

/* Loading背景画面設定　*/
#splash {
    /*fixedで全面に固定*/
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background:#fff;
  text-align:center;
  color:#fff;
}

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Loading アイコンの大きさ設定　*/
#splash_logo img {
  width:260px;
}

/* fadeUpをするアイコンの動き */

.fadeUp{
animation-name: fadeUpAnime;
animation-duration:3s;
animation-fill-mode:forwards;
opacity: 0;
}

@keyframes fadeUpAnime{
  from {
    opacity: 0;
//   transform: translateY(100px);
  }

  to {
    opacity: 1;
  transform: translateY(0);
  }
}